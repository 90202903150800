.container {
  padding: 0 2rem;
}

.main {
  min-height: 60vh;
  display: grid;
  place-content: center;
}

@media (max-width: 450px) {
  .container {
    padding-top: 4rem;
  }
}
