@import url(https://fonts.googleapis.com/css?family=Roboto:500);
 .GoogleLoginButton_google_btn__Fc5_2 {
	 cursor: pointer;
     display: flex;
	 width: 220px;
	 height: 42px;
	 background-color: #4285f4;
	 border-radius: 2px;
	 box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
}
 .GoogleLoginButton_google_btn__Fc5_2 .GoogleLoginButton_google_icon_wrapper__Y8B7a {
	 /* position: absolute; */
	 margin-top: 1px;
	 margin-left: 1px;
	 width: 40px;
	 height: 40px;
	 border-radius: 2px;
	 background-color: #fff;
}
 .GoogleLoginButton_google_btn__Fc5_2 .GoogleLoginButton_google_icon__K9RdB {
	 position: absolute;
	 margin-top: 11px;
	 margin-left: 11px;
	 width: 18px;
	 height: 18px;
}
 .GoogleLoginButton_google_btn__Fc5_2 .GoogleLoginButton_btn_text__UJyXy {
	 float: right;
	 /* margin: 11px 11px 0 11px; */
	margin: auto;
	 color: #fff;
	 font-size: 14px;
	 letter-spacing: 0.2px;
	 font-family: "Roboto";
}
 .GoogleLoginButton_google_btn__Fc5_2:hover {
	 box-shadow: 0 0 6px #4285f4;
}
 .GoogleLoginButton_google_btn__Fc5_2:active {
	 background: #1669f2;
}
 
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
 .FacebookLoginButton_facebook_btn__HPDC5 {
	 cursor: pointer;
     display: flex;
	 width: 220px;
     margin-top: 12px;
	 height: 42px;
	 background-color: #3c72ea;
	 border-radius: 2px;
	 box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
}
 .FacebookLoginButton_facebook_btn__HPDC5 .FacebookLoginButton_facebook_icon_wrapper__t1lSY {
	 margin-top: 1px;
	 margin-left: 1px;
	 width: 40px;
	 height: 40px;
}
 .FacebookLoginButton_facebook_btn__HPDC5 .FacebookLoginButton_facebook_icon__dsDvN {
	 position: absolute;
     width: 24px;
     height: 24px;
     margin-top: 8px;
     margin-left: 8px;
}
 .FacebookLoginButton_facebook_btn__HPDC5 .FacebookLoginButton_btn_text__sGc7M {
	 float: right;
	 margin: 11px 11px 0 11px;
	 color: #fff;
	 font-size: 14px;
	 letter-spacing: 0.2px;
	 font-family: "Roboto";
}
 .FacebookLoginButton_facebook_btn__HPDC5:hover {
	 box-shadow: 0 0 6px #487ae9;
}
 .FacebookLoginButton_facebook_btn__HPDC5:active {
	 background: #2e68e7;
}
 
.Logo_logo__qaQep {
  width: 250px;
  z-index: 80;
  cursor: pointer;
  transform: translateY(-20px) translateX(-10px)
}

@media (max-width: 1024px) {
  .Logo_logo__qaQep {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .Logo_logo__qaQep {
    width: 180px;
  }
}

.MarketplaceSearchButton_button__DxFqC {
  border: none;
  border-bottom: 2px solid var(--main-color-medium);
  color: var(--main-color-medium);
  transition: all 0.3s ease-in-out;

  position: relative;
}

.MarketplaceSearchButton_button__DxFqC:hover {
  border: none;
  color: var(--main-color-synblue);
  border-bottom: 2px solid var(--main-color-synblue);
}

.InviteFriendInfoModal_modal__xo8IJ {
  position: absolute;
  top: 1rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.InviteFriendInfoModal_close_button__dxATo {
  background-color: transparent;
  box-shadow: none !important;
  color: black;
  position: absolute;
  top: 0.75rem;
  right: 0;
}

.InviteFriendInfoModal_close_button__dxATo:hover {
  background-color: transparent;
}

.InviteFriendInfoModal_table_cell_bold__QtWPE {
  font-weight: bold;
  font-size: 12px;
  border: none !important;
}

.InviteFriendInfoModal_table_cell__Ru43n {
  border: none !important;
}

.Navbar_nav__8sUxk {
  margin-right: 2rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999;
  background-color: #fff;
}

.Navbar_navbar__icons__N1Msz {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.Navbar_nav__button__gfGpL {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem;
  margin: 0 0.5rem;
  color: var(--main-color-medium);
  background-color: transparent;
}
.Navbar_nav__button__gfGpL:hover {
  background-color: var(--main-color-light);
}

.Navbar_nav__button__icon__seJTW {
  font-size: 2rem;
}

.Navbar_nav__button__icon__auth__lFI9o {
  font-size: 2rem;
  border-radius: 100%;
  color: var(--main-color-syngreen-dark);
}

.Navbar_nav__button__icon__authenticated__YJSLT {
  position: absolute;
  bottom: 8px;
  right: 6px;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  color: var(--main-color-light);
  background-color: var(--main-color-syngreen-dark);
}

.Navbar_nav__button__icon__notifications__TeDNt {
  position: absolute;
  bottom: 8px;
  right: 6px;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  color: var(--main-color-light);
  background-color: var(--main-color-syngreen-dark);
}

@media (max-width: 700px) {
  .Navbar_nav__button__icon__seJTW {
    font-size: 1.8rem;
  }

  .Navbar_nav__button__icon__auth__lFI9o {
    font-size: 1.8rem;
    border-radius: 100%;
    color: var(--main-color-syngreen-dark);
  }

  .Navbar_nav__button__icon__authenticated__YJSLT {
    font-size: 0.8rem;
  }

  .Navbar_nav__button__icon__notifications__TeDNt {
    font-size: 0.8rem;
  }

  .Navbar_nav__8sUxk {
    margin-right: 0.2rem;
  }
}

@media (max-width: 450px) {
  .Navbar_container__w3JnY {
    position: fixed;
    bottom: 0px;
    left: 0px;
    margin: 0px;
    z-index: 999999999;
    background-color: #fff;
    width: 100%;
  }

  .Navbar_help__button__vsh9G {
    display: none;
  }

  .Navbar_nav__8sUxk {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .Navbar_nav__button__icon__seJTW {
    stroke: #fff;
    stroke-width: 1px;
  }

  .Navbar_navbar__buttons__cyVgO {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px -10px 10px rgb(0 0 0 / 8%);
  }

  .Navbar_nav__button__gfGpL {
    color: grey;
  }

  .Navbar_nav__button__text__EgRsC {
    font-size: 1.2rem;
    font-weight: lighter;
  }
}

.CategoriesMobile_custom__order__eTAZC {
  width: 100%;
}
.CategoriesMobile_button__custom__order__Q_EfG {
  background-color: #272727;
  color: #fff;
  font-weight: bold !important;
  border-radius: 0px;
  width: 100%;
}

.CategoriesMobile_stack__item__text__dKFJn {
  color: #272727;
}

.CategoriesMobile_menu__accordion__HYw0B {
  border: 0px;
  box-shadow: none;
}

.CategoriesMobile_menu__accordion__HYw0B::before {
  content: none;
}

.CategoriesMobile_menu__accordion__details__9SD3G {
  padding: 2% 10%;
}

.CategoriesMobile_menu__mobile__accordion__details__qdmjs {
  margin-top: 3%;
  padding: 0rem 1rem;
}

.CategoriesMobile_categories__mobile__father__Hrxis {
  max-height: 75vh;
  overflow: scroll;
  z-index: 105;
}

.CategoriesMobile_categories__mobile__father__Hrxis::-webkit-scrollbar {
  display: none;
}

.CategoriesMobile_menu__mobile__buttons__2iqbA {
  padding-left: 1rem;
  margin: 15px;
}

.MenuHistory_menu__history__ayzde {
  display: flex;
  justify-content: flex-start;
  margin-left: 0.5rem;
}

.MenuHistory_menu__history__ayzde p {
  max-width: 100vw;
  margin-left: 0.5rem;
  font-weight: 700;
  text-align: left;
  color: #272727;
}

.MenuHistory_menu__history__ayzde svg {
  color: #272727;
}

.Header_header__RvT3c {
  color: var(--main-color-medium);
  position: relative;
  margin-bottom: 0.2rem;
  z-index: 9999;
}

.Header_navbar__qb1eg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}

@media (max-width: 450px) {
  .Header_header__RvT3c {
    position: fixed;
    bottom: 0px;
    left: 0px;
    margin: 0px;
    z-index: 999999999;
    background-color: #fff;
    width: 100%;
  }

  .Header_container__header__up__6OVEI span {
    max-width: 150px;
  }

  .Header_categories__mobile__WvKnZ {
    position: fixed;
    transform: translateY(-100%);
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    z-index: 99;
    transition: 300ms all ease-in-out;
    padding: 0 5%;
  }

  .Header_categories__mobile__WvKnZ.Header_expanded__crOuB {
    position: fixed;
    transform: translateY(0%);
    padding-top: 100px;
    z-index: 99;
    transition: 300ms all ease-in-out;
  }
}

.Footer_footer__2MMdT {
  display: flex;

  position: relative;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1rem;

  width: 100%;
}

.Footer_footer__links__GpU4j {
  align-self: center
}

.Footer_footer__trustseal__PcfX_ {
  padding-top: 5%;
}

.Footer_footer__ecommerce__av8R2 {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.Footer_footer__legal__Ripri,
.Footer_footer__social__10uvU {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}

.Footer_footer__social__10uvU {
  margin-bottom: 8px;
  gap: 25px;
}

.Footer_footer__legal__Ripri .Footer_link__OMLqp,
.Footer_footer__social__10uvU .Footer_link__OMLqp {
  font-size: 0.7rem;
  padding-top: 3px;
  padding-left: 5px;
}

.Footer_footer__social__10uvU .Footer_instagram__AztZE {
  width: 20px;
  height: 20px;
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  color: whitesmoke;
  border-radius: 20%;
}

.Footer_footer__social__10uvU .Footer_youtube__31Njo {
  width: 20px;
  height: 20px;
  color: #ff0000;
  border-radius: 20%;
}

.Footer_footer__social__10uvU .Footer_x__nGw_o {
  width: 20px;
  height: 20px;
  color: #000000;
  border-radius: 20%;
}

.Footer_footer__social__10uvU .Footer_facebook__hQF9N {
  width: 20px;
  height: 20px;
  color: #036de4;
  border-radius: 20%;
}

.Footer_footer__badges__pW95k {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 22%;
  align-items: center;
  align-self: center;
}

.Footer_footer__badges__pW95k .Footer_title__1ogTI {
  text-align: center;
  margin-left: -10px;
  font-size: 0.8rem;
  width: 100%;
}

.Footer_badge__8qiRG {
  height: -moz-min-content;
  height: min-content;
}

@media (max-width: 720px) {
  .Footer_footer__2MMdT {
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem;
    gap: 2rem;
  }

  .Footer_footer__ecommerce__av8R2,
  .Footer_footer__badges__pW95k,
  .Footer_footer__legal__Ripri,
  .Footer_footer__social__10uvU {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .Footer_social__B92hy:hover {
    color: var(--main-color-synblue);
  }

  .Footer_footer__social__10uvU {
    gap: 0.2rem;
  }

  .Footer_link__OMLqp {
    margin: 0;
    font-size: 0.8rem;
  }
}

@media (max-width: 450px) {
  .Footer_footer__2MMdT {
    display: none;
  }
}

.MarketplaceStack_stack__3s6EH {
  align-items: center;
  justify-content: center;
}

.MarketplaceStack_stack__item__PrMxF,
.MarketplaceStack_stack__item__all__QuSCp {
  cursor: pointer;
  transition: all 0.3s ease;
}

.MarketplaceStack_stack__item__PrMxF {
  color: var(--main-color-syndark);
  background-color: transparent;
}

.MarketplaceStack_stack__close__qkmJx {
  position: absolute;
  right: 0;
}

.MarketplaceStack_stack__item__text__vURB2 {
  font-size: 0.9rem;
}

.MarketplaceStack_stack__item__PrMxF:hover > .MarketplaceStack_stack__item__text__vURB2 {
  font-weight: bold;
}

.MarketplaceStack_stack__item__text_active__LqT69 {
  font-weight: bold;
}

.MarketplaceStack_stack__subcategories__KmX3R .MarketplaceStack_stack__item__text__vURB2 {
  font-size: 0.8rem;
}

.MarketplaceStack_stack__subcategories__KmX3R {
  padding: 0.4rem;
  width: 80vw;
  border-radius: 4px;
  background-color: #fff;
  z-index: 9999;
  margin-top: -5px;
  background-color: transparent;
  display: contents;
}

@media (max-width: 1024px) {
  .MarketplaceStack_stack__item__all__QuSCp {
    width: 100vw;
  }

  .MarketplaceStack_stack__subcategories__KmX3R {
    z-index: 10;
    width: 80vw;
  }
}

@media (max-width: 450px) {
  .MarketplaceStack_stack__item__all__QuSCp {
    display: none;
  }
}

/* TODO
* Check if it is necessary to make a scroll from main section
*/
@media (max-width: 450px) {
}


.Layout_carrousel_banner__0eEjK {
    margin-left: 85px;
    margin-right: 85px; 
    width: calc(100% - 170px);
}


@media (max-width: 1025px) {
    .Layout_carrousel_banner__0eEjK {
        margin-left: 65px;
        margin-right: 65px; 
        width: calc(100% - 130px);
    }
}
  
.Login_container__TKPt4 {
  padding: 0 2rem;
}

.Login_main__gWxqA {
  min-height: 60vh;
  display: grid;
  place-content: center;
}

@media (max-width: 450px) {
  .Login_container__TKPt4 {
    padding-top: 4rem;
  }
}

